.video_page .video_cards {
    grid-template-columns: repeat(3, 1fr);
    column-gap           : 2rem;
    row-gap              : 9rem;
}

.video_page .video_categories {
    grid-template-columns: repeat(auto-fit, minmax(15.9rem, 18rem));
    gap                  : 2.6rem;
    margin-bottom        : 4.5rem;
}

@media (max-width: 575.98px) {
    .video_page .video_cards {
        grid-template-columns: 1fr;
        row-gap              : 6rem;
    }
}