.navbar {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    z-index : 2;
}

.navbar.bg_dark {
    background-color: var(--white);
}

.navbar .flex_box {
    align-items    : center;
    justify-content: space-between;
    padding        : 1.6rem 0;
}

.navbar .logo_wrapper {
    width: 5.4rem;
}

.navbar .menu_toggle {
    display: none;
}

@media (max-width: 1399.98px) {
    .navbar .flex_box {
        padding: 1.6rem 0;
    }
}

@media (max-width: 575.98px) {
    .navbar .menu_lg_wrapper {
        display: none;
    }

    .navbar .menu_toggle {
        display: block;
    }

    .navbar .btn_menu_toggle {
        font-size: 2.8rem;
    }
}