.modal,
.modal_backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.modal_backdrop {
    background-color: rgba(0, 0, 0, .7);
}

.modal_dialog {
    position      : relative;
    max-width     : 120rem;
    margin        : 1.75rem auto;
    pointer-events: none;
}

.modal_dialog_scrollable {
    height: calc(100% - 3.5rem);
}

.modal_dialog_centered {
    display    : flex;
    align-items: center;
    min-height : calc(100% - 3.5rem);
}

.modal_content {
    position        : relative;
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    background-color: var(--white);
    border-radius   : 1rem;
    pointer-events  : auto;
    z-index         : 2;
}

.modal_dialog_scrollable .modal_content {
    max-height: 100%;
    overflow  : hidden;
}

.modal_dialog_scrollable .modal_body::-webkit-scrollbar {
    display: none;
}

.modal_dialog_scrollable .modal_body {
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.modal_close {
    position : absolute;
    top      : 0;
    right    : .5rem;
    font-size: 2.4rem;
    color    : var(--white);
}

@media screen and (max-width: 1399.98px) {
    .modal_dialog {
        max-width: 95rem;
    }
}

@media screen and (max-width: 767.98px) {
    .modal_dialog {
        margin: 1.75rem 1rem;
    }
}