.video_category {
    font-weight   : 500;
    font-size     : 1.6rem;
    line-height   : 2.5rem;
    letter-spacing: 0.2em;
    color         : #575757;
    border-radius : 5rem;
    padding       : .7rem 2.5rem;
}

.video_category:not(.active) {
    border: 1px solid #575757;
}

.video_category.active {
    color           : var(--white);
    background-color: #9EB9B0;
}