:root {
  --primary: #9EB9B0;
}

body {
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size  : 1.6rem;
  line-height: 2.4rem;
  color      : var(--black);
}


/* common classses */
.container {
  --containerPadding: 2.4rem;
  --containerWidth  : calc(143.6rem + var(--containerPadding) * 2);
  max-width         : calc(var(--containerWidth));
  padding           : 0 var(--containerPadding);
  margin            : 0 auto;
}

.flex_box {
  display  : flex;
  flex-wrap: wrap;
}

.grid_box {
  display: grid;
}

.w_100 {
  width: 100%;
}

.page_padding {
  padding: 18rem 0 16rem;
}

.page_title {
  position      : relative;
  font-family   : 'Open Sans', sans-serif;
  font-weight   : 500;
  font-size     : 10rem;
  text-transform: capitalize;
  line-height   : 13rem;
  margin-bottom : 5rem;
}

.page_title::after {
  content         : '';
  position        : absolute;
  right           : 0;
  bottom          : 4.8rem;
  width           : 70%;
  height          : 2px;
  background-color: #9EB9B0;
}


.sc_title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size  : 5.5rem;
  line-height: 7.5rem;
  color      : var(--black);
}

.sc_title>span {
  color: var(--primary);
}


.desc_box p, .desc {
  font-weight: 300;
  font-size  : 2.0rem;
  line-height: 3.4rem;
  color      : var(--gray-6);
}

.btn_more {
  display      : inline-flex;
  align-items  : center;
  color        : var(--gray-7);
  padding      : .6rem;
  border       : 1px solid var(--gray-7);
  border-radius: .3rem;
}

.btn_more>span {
  font-size       : 1.5rem;
  line-height     : 1.8rem;
  text-transform  : capitalize;
  color           : var(--white);
  background-color: var(--gray-7);
  border-radius   : .3rem;
  padding         : 1.2rem 1.8rem;
}

.btn_more svg {
  width      : 2rem;
  height     : 2rem;
  margin-left: 1rem;
}

.play_icon_box {
  position: relative;
}

.play_icon_box .play_icon {
  position : absolute;
  top      : 50%;
  left     : 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1399.98px) {
  .page_padding {
    padding: 15rem 0 15rem;
  }

  .page_title {
    font-size  : 8rem;
    line-height: 10rem;
  }
}

@media (max-width: 575.98px) {
  .container {
    --containerPadding: 1.6rem;
  }

  .page_padding {
    padding: 12rem 0 8rem;
  }

  .page_title {
    font-size    : 6rem;
    line-height  : 7rem;
    margin-bottom: 4rem;
  }

  .page_title::after {
    bottom: 2.5rem;
    width : 40%;
  }

  .sc_title {
    font-size  : 4rem;
    line-height: 4.8rem;
  }

  .desc {
    font-size  : 1.8rem;
    line-height: 2.8rem;
  }

  .play_icon_box .play_icon {
    width: 4rem;
  }
}