.about_page .sc_title {
    color        : var(--lightBlue);
    margin-bottom: 3rem;
}

.about_page .big_thumb {
    float    : left;
    width    : 100%;
    max-width: 56.4rem;
    margin   : 1.5rem 10rem 5rem 0;
}


.about_page p {
    font-size  : 2rem;
    line-height: 3.8rem;
}

.about_page p:not(:last-child) {
    margin-bottom: 3rem;
}

@media (max-width: 575.98px) {
    .about_page .big_thumb {
        float: initial;
        margin: 0 0 3rem 0;
    }
    .about_page p {
        font-size  : 1.8rem;
        line-height: 3.4rem;
    }
}