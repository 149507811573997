.single_blog .container {
    position: relative;
}

.single_blog .sc_title {
    margin-bottom: 3rem;
}

.single_blog .date_time {
    font-family: 'Inter', sans-serif;
    font-style : italic;
    font-size  : 1.4rem;
    line-height: 1.7rem;
    color      : #999999;

    position: absolute;
    top     : 3.8rem;
    right   : 2.4rem;
}

.single_blog .img_box img {
    width          : 100%;
    height         : 55.7rem;
    object-fit     : cover;
    object-position: center center;
}

.single_blog .desc_box p {
    margin-top: 2.4rem;
}

@media (max-width: 575.98px) {
    /* .single_blog .sc_title {
        margin-bottom: 5rem;
    } */
    .single_blog .date_time {
        top        : 6.2rem;
        right      : auto;
    }
}