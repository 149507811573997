.blog_card .img_box {
    width        : 100%;
    height       : 27rem;
    border-radius: 1rem;
    overflow     : hidden;
}

.blog_card .img_box img {
    min-width      : 100%;
    height         : 27rem;
    object-fit     : cover;
    object-position: center center;
}

.blog_card h4 {
    font-weight   : 500;
    font-size     : 1.6rem;
    line-height   : 2.5rem;
    color         : var(--black);
    letter-spacing: 0.2em;
    margin-top    : 2rem;
}

.blog_card p {
    font-size  : 1.6rem;
    line-height: 2.8rem;
    color      : var(--gray-6);
    margin-top : 2.2rem;

    display           : -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

@media (max-width: 575.98px) {
    .blog_card p {
        margin-top : 1.2rem;
    }
}