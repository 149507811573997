.blog_page {
    background-color: var(--dipBlue);
}

.blog_page .sc_title {
    color: var(--white);
    margin-bottom: 4rem;
}

.blog_page .grid_box {
    grid-template-columns: repeat(3, 1fr);
    column-gap           : 4.4rem;
    row-gap              : 7.2rem;
}

@media (max-width: 575.98px) {
    .blog_page .grid_box {
        grid-template-columns: 1fr;
        row-gap              : 5rem;
    }
}

