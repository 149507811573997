.video_card {
    width     : 100%;
    text-align: left;
}

.video_card .img_box {
    position     : relative;
    border-radius: 1rem;
    overflow     : hidden;
}

.video_card h4 {
    font-weight   : 500;
    font-size     : 1.6rem;
    line-height   : 2.5rem;
    letter-spacing: 0.2em;
    color         : var(--black);
    margin-top    : 2rem;
}