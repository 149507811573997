nav a {
    display       : inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight   : 600;
    font-size     : 1.6rem;
    line-height   : 2.2rem;
    text-transform: capitalize;
    color         : var(--black);
    padding: .8rem 0;
}

nav a.active {
    border-bottom: 2px solid var(--primary);
}

nav a:not(:last-child) {
    margin-right: 4.5rem;
}