.video_list_card {
    margin-top: 3.5rem;
}
/* .video_list_card:not(:last-child) {
    margin-bottom: 4rem;
} */

.video_list_card button {
    width     : 100%;
    text-align: left;
}

.video_list_card .grid_box {
    grid-template-columns: 18rem auto;
    align-items          : center;
    column-gap           : 3.5rem;
}

.video_list_card .content span {
    display    : block;
    font-weight: 500;
    font-size  : 1.6rem;
    line-height: 2.5rem;
    color      : var(--gray-5);
}

.video_list_card .content p {
    font-weight: 500;
    font-size  : 2rem;
    line-height: 2.5rem;
    color      : var(--gray-5);
    margin-top : 1.5rem;

    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

@media (max-width: 575.98px) {
    .video_list_card .grid_box {
        grid-template-columns: 12rem auto;
        column-gap           : 1.5rem;
    }

    .video_list_card .content span {
        font-size  : 1.4rem;
        line-height: 2rem;
    }
    .video_list_card .content p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-top: .8rem;
    }
}