.home_video {
    padding: 11.5rem 0;
}

.home_video .sc_title {
    margin-bottom: 3rem;
}

.home_video>div>.grid_box {
    grid-template-columns: 50rem auto;
    column-gap           : 7rem;
}

@media (max-width: 575.98px) {
    .home_video {
        padding: 8rem 0;
    }

    .home_video>div>.grid_box {
        grid-template-columns: 1fr;
        row-gap: 4rem;
    }
}