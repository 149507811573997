.social_wrapper>a {
    display        : inline-block;
    width          : 2.8rem;
    height         : 2.8rem;
    border         : 1.5px solid var(--white);
    border-radius  : 50%;
    padding        : 0.4rem;
    display        : inline-flex;
    justify-content: center;
    align-items    : center;
    margin         : 0 .8rem;
}

.social_wrapper>a svg {
    width    : 100%;
    height   : 100%;
    /* stroke: var(--white);
    fill     : var(--white); */
}

/* .social_wrapper > a path {
    stroke: var(--white);
} */
.social_wrapper>a svg>g>path:nth-child(2) {
    fill: var(--white);
}

.social_wrapper>a.facebook:hover svg>g>path:nth-child(2) {
    fill: rgb(6, 96, 126);
}

.social_wrapper>a.linkedin:hover svg>g>path:nth-child(2) {
    fill: rgb(10, 102, 194)
}
.social_wrapper>a.instagram:hover svg>g>path:nth-child(2) {
    fill: rgb(200, 50, 200);
}

.social_wrapper>a.youtube:hover svg>g>path:nth-child(2) {
    fill: red;
}