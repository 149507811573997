.video_player iframe {
    width        : 100%;
    min-height   : 67.5rem;
    margin-bottom: -0.7rem;
}

@media (max-width: 1399.98px) {
    .video_player iframe {
        min-height: 47.5rem;
    }
}

@media (max-width: 575.98px) {
    .video_player iframe {
        min-height: 22rem;
    }
}