.menu_sm,
.menu_sm_overlary {
    transition: transform .3s ease-in-out;
}

.menu_sm:not(.show),
.menu_sm_overlary:not(.show) {
    transform: translateX(100%);
}

.menu_sm {
    position        : fixed;
    top             : 0;
    right           : 0;
    width           : 24rem;
    height          : 100vh;
    background-color: var(--white);
    padding         : 6rem 1.6rem 3rem;
    z-index         : 3;
}

.menu_sm_overlary {
    position        : fixed;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index         : 2;
}

.btn_menu_close {
    position : absolute;
    top      : 1rem;
    right    : 1rem;
    font-size: 2.8rem;
}

.menu_sm_links>a {
    display       : block;
    font-family   : 'Open Sans', sans-serif;
    font-weight   : 600;
    font-size     : 1.6rem;
    line-height   : 2.2rem;
    text-transform: capitalize;
    color         : var(--black);
    padding       : .8rem 0;
}

.menu_sm_links a.active {
    border-bottom: 2px solid var(--primary);
}

.menu_sm_links a:not(:last-child) {
    margin-bottom: 2.5rem;
}