.single_video_card {
    width        : 100%;
    text-align   : left;
    border-radius: 1rem;
    overflow     : hidden;
}

.single_video_card .thumb_img {
    width          : 100%;
    height         : 60rem;
    object-fit     : cover;
    object-position: center center;
}

.single_video_card .content {
    position: absolute;
    left    : 0;
    bottom  : 0;
    padding : 2.8rem 3.4rem;
}

.single_video_card .content span {
    display    : inline-block;
    font-weight: 500;
    font-size  : 1.6rem;
    line-height: 2.5rem;
    color      : var(--white);
}

.single_video_card .content p {
    font-weight: 500;
    font-size  : 2rem;
    line-height: 2.5rem;
    color      : var(--white);
    margin-top : 1.5rem;
}

@media (max-width: 575.98px) {
    .single_video_card .thumb_img {
        height: auto;
    }
}