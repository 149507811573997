.footer {
  background: var(--primary);
  padding   : 2.4rem 4rem;
  position  : relative;
}

.footer .flex_box:nth-child(1),
.footer .flex_box:nth-child(2) {
  display    : flex;
  align-items: center;
}

.footer .flex_box:nth-child(1) {
  justify-content: space-between;
  border-bottom: 1px solid var(--white);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.footer .flex_box:nth-child(2) {
  justify-content: center;
}

.footer h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size  : 1.4rem;
  line-height: 1.6rem;
  color      : var(--white);
}

.footer h3 a {
  color        : var(--white);
  border-bottom: 1px solid var(--white);
}

.footer h3 a:hover {
  color        : gold;
  border-bottom: 1px solid gold;
}

@media (orientation: portrait) {

  .footer {
    padding: 2.4rem 1.6rem;
  }

  .footer h3 {
    font-weight: 400;
  }
}