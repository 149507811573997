.home_blog {
    padding: 8.2rem 0 11.2rem;
}

.home_blog .sc_title {
    text-align: center;
}

.home_blog .grid_box {
    grid-template-columns: repeat(3, 1fr);
    column-gap           : 9.2rem;
    padding-top          : 8rem;
}


@media (max-width: 1399.98px) {
    .home_blog .grid_box {
        column-gap: 5.2rem;
    }
}

@media (max-width: 575.98px) {
    .home_blog {
        padding: 8rem 0 8rem;
    }

    .home_blog .grid_box {
        grid-template-columns: 1fr;
        row-gap              : 5rem;
        padding-top          : 5rem;
    }
}