.banner_slider img {
    height         : 100vh;
    width          : 100%;
    object-fit     : cover;
    object-position: bottom right;
}

.sm_img {
    display: none;
}

@media (max-width: 1399.98px) {
    .banner_slider img {
        object-position: center right;
    }
}

@media (max-width: 575.98px) {

    .lg_img {
        display: none;
    }

    .sm_img {
        display: block;
    }

}