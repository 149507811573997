.about_me {
    padding: 11.5rem 0;
    background-color: #F7F7F7;
}

.about_me .grid_box {
    grid-template-columns: auto 53.4rem;
    align-items: center;
    column-gap           : 26rem;
}

.about_me .sc_title, .about_me .desc_box  {
    margin-bottom: 3.2rem;
}

.about_me .desc_box .desc {
    display           : -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow          : hidden;
}

.about_me .img_box {
    border-radius: 1rem;
    overflow: hidden;
}


@media (max-width: 575.98px) {
    .about_me {
        padding: 8rem 0;
    }
    .about_me .grid_box {
        grid-template-columns: 1fr;
        row-gap: 5rem;
    }
}